import {ActionFunctionArgs} from '@remix-run/node';
import {redirect, useActionData, useNavigation} from '@remix-run/react';
import {Eye, EyeOff} from 'lucide-react';
import {useState} from 'react';
import {Button} from '~/components/ui/button';
import {Spinner} from '~/components/ui/spinner';
import {remixCaller} from '~/routes/api.$trpc/api.server';
import {sessionService} from '~/routes/api.$trpc/session.server';
import {AuthField, AuthForm, AuthLink} from '~/routes/auth+/register/route';
import {services} from '~/services/services.server';

export const action = async ({request}: ActionFunctionArgs) => {
  try {
    const fetcher = await remixCaller(request);
    const data = await services.form.getRequestData(request);
    const {token} = await fetcher.auth.login({...data});
    const {headers} = await sessionService.auth.create(request.headers, {token});

    return redirect('/app/dashboard', {headers});
  } catch (error) {
    return {error: {message: 'Mot de passe ou email incorrect'}};
  }
};

export default function LoginPage() {
  const data = useActionData() as {error: {message: string}} | undefined;
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';
  return (
    <AuthForm title="Se connecter">
      <AuthField autoFocus label="Email / Identifiant" required name="email" />
      <div className="relative">
        <AuthField label="Mot de passe" required name="password" type={showPassword ? 'text' : 'password'} />
        <Button
          onClick={toggleShowPassword}
          size="icon-rounded"
          variant="ghost"
          className="w-[40px] absolute top-[52px] right-2"
          type="button">
          {showPassword ? <Eye size={24} /> : <EyeOff size={24} />}
        </Button>
      </div>
      {data?.error && <div className="text-red-500 text-sm">{data.error.message}</div>}
      <Button size="xl" variant="quaternary" type="submit" className="mt-24">
        Se connecter{isLoading && <Spinner className="ml-12 size-8" />}
      </Button>
      <AuthLink to="/auth/forgot-password">Mot de passe oublié ?</AuthLink>
      <AuthLink to="/auth/register" className="text-secondary font-bold">
        Pas encore inscrit ?
      </AuthLink>
    </AuthForm>
  );
}
